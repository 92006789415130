<template>
  <div class="containers">
    <div id="doms">
      <div class="title">入职告知书</div>

      <div class="inputBox">
        <div class="itemTitle">一、入职资料（入职当天提供）</div>
        <div class="itemText">身份证、毕业证、户口本索引页及个人页、体检报告单（医院出具的原件）、结婚证、原单位离职证明(如不能提供，本人需写明原因，由公司安排自行到原单位去调查）</div>
        <div class="itemText">1：以上均需提供原件，由人事专员验证后上传公司人事系统，毕业证由人事负责验证，部分资料留存复印件。</div>
        <div class="itemText">2：资料不齐全的将影响开通公司办公系统权限，无法计算实习时间（特殊情况需报总经理批准后才可开通）。</div>
        <div class="itemTitle">二、实习期考核的内容</div>
        <div class="itemText">1：诚实守信。这是公司的基本价值观，提供虚假或不完整的信息资料，使公司做出错误的判断以及工作中弄虚作假都会以此解除劳动合同。</div>
        <div class="itemText">2：主动学习能力。无论你是什么学历，什么背景，决定你在本公司前景的一项重要技能是你在这里的再学习能力。你要按岗位的目标，掌握本岗位的基本技能，迅速主动的提升自己，以奠定自己的竞争力，并计划向更高的目标迈进。</div>
        <div class="itemText">3：工作的韧劲。该岗位的工作难度与你曾经的学习经历相比没有任何困难，你要具备不达目标决不妥协的精神，要利用好你周围以及公司为你提供的一切资源，去实现目标，不向困难低头。</div>
        <div class="itemText">4：勤奋、专注。任何工作成绩的取得，都带有勤奋专注的特质，如果你只是为找到一份工作，但你仍然计划并从事着其他工作，只是在这里付出一些时间，没有努力向上、争取更好的强烈意愿，那么你在这里照样不会取得成就，您不要从事该工作。</div>
        <div class="itemText">5：日常工作，除了在工作中需要完成基本岗位职责外，还需满足本岗位部门负责人的工作要求。</div>
        <div class="itemTitle">三、实习时间</div>
        <div class="itemText">A.职业技能岗（仓库基层岗位、驾驶员等）1-2个月，最长不得超过3个月</div>
        <div class="itemText">B.技术岗（研发、后勤、营销）；2-3个月，最长不得超过3个月（特殊情况由负责人提出可延长1-2个月）</div>
        <div class="itemText">C.管理岗（一般内部选拔不外聘）实习期3-6个月，超期则视为不合格</div>
        <div class="itemTitle">四、劳动合同</div>
        <div class="itemText">1：入职即签署劳动合同：</div>
        <div class="itemText">①客服经理岗位：签订劳动合同最低工作年限为≧1年；</div>
        <div class="itemText">②仓储物流基础岗位：签订劳动合同最低工作年限为≧1年；</div>
        <div class="itemText">③研发技术岗位：签订劳动合同最低工作年限为≧2年；</div>
        <div class="itemText">④管理岗位：签订劳动合同最低工作年限为≧3年；</div>
        <div class="itemText">⑤其他岗位：签订劳动合同最低工作年限为≧2年；</div>
        <div class="itemText">2：公司为入职后的正式员工缴纳五险。实习结束后办理转正申请，考核通过转为正式员工。正式员工提交社保缴纳申请，提交相关有效资料，由人事部门负责办理缴纳手续，个人承担应由个人缴纳部分。</div>
        <div class="itemText">3：本人已知悉公司为员工缴纳社保的通知，如本人未提交缴纳社保的材料，则视为本人自愿放弃缴纳社保，并承诺不会因此提出额外的任何要求。</div>
        <div class="itemText">4.本人需保证以往工作履历完整且真实有效，否则公司有权解除劳动合同。</div>
        <div class="itemText">5.{{fiveText}}</div>
      </div>
      <van-field
          style="background: #F7F8FA"
          v-model="query.sign_info"
          type="textarea"
          :autosize="{ minHeight: 150}"
          placeholder="请再次录入第五条(中文标点符号)"
      />
      <div class="sign">
        <span class="signLabel">入职人签字:</span>
        <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :bgColor.sync="bgColor" :lineColor="'#000'" />
        <img v-else class="card" :src="query.sign_file" alt="">
      </div>
      <div class="reset" @click="resetSign">重新签名</div>
      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
<!--    <img src="../../../static/noPaper/hetong.png" alt="" class="zhang">-->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import axios from "axios";
import {saveEntry, loadEntry, uploadFile} from "@/api/paper";
export default {
  data() {
    return {
      query: {
        sign_info: ''
      },
      isShow: true,
      bgColor: '',
      fiveText: '本人承诺所提供的工作履历是完整的和真实的，如有任何不真实的信息，本人愿意接受被公司立即辞退并解除劳动合同，个人不附加任何的诉求。'
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      loadEntry({openId: this.$store.state.info.openId}).then(res => {
        if(res.row && res.row.sign_info) {
          this.query = res.row
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async submitInfo() {
      if(!this.query.sign_info) {
        return this.$notify({
          type: "warning",
          message: "请录入第五条~",
        });
      }
      if(this.query.sign_info !== this.fiveText) {
        return this.$notify({
          type: "warning",
          message: "请检查录入信息，保持一致",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      saveEntry(this.query).then(res => {
        console.log(res);
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        }
      })
    },
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .containers {
  }
  #doms {
    box-sizing: border-box;
    padding: 14px 10px;
  }
  .inputBox {
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    color: #666666;
    line-height: 20px;
    .itemTitle {
      font-weight: bold;
    }
  }
  .tips {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 11px;
    color: #777777;
    line-height: 18px;
    margin: 18px 0;
  }
  .sign {
    margin-top: 50px;
    .signLabel {
      flex-shrink: 0;
      font-family: PingFang SC;
      font-size: 15px;
      color: #666666;
    }
    .card {
      width: 100%;
      height: 70px;
      background: #F7F8FA;
      border: 1px solid #DDDDDD;
    }
  }
  .reset {
    position: absolute;
    right: 10px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #02DBCA;
    color: #02DBCA;
    border-radius: 10px;
    margin-top: 15px;
  }
  .submitBtn {
    background: #02DBCA;
    border-radius: 20px;
    color: #FFFFFF;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .itemText {
    text-indent: 2em;
  }
  .zhang {
    position: fixed;
    width: 180px;
    height: 180px;
    right: 10px;
    bottom: 150px;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 60px 0;
    color: #000000;
  }
</style>
